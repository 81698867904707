(() => {
    const $subMenuDropdownWraps = document.querySelectorAll('.site-menu-bar-menu .menu-item-has-children');
    const $subMenuWraps = document.querySelectorAll('.site-menu-bar .menu-item-has-children .menu-item-has-children');

    let getSiblings = function (e) {
        // for collecting siblings
        let siblings = []; 
        // if no parent, return no sibling
        if(!e.parentNode) {
            return siblings;
        }
        // first child of the parent node
        let sibling  = e.parentNode.firstChild;
        
        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== e) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
    };

    const enableSubMenu = ($subMenuWrap) => {
        if ($subMenuWrap.classList.contains('menu-item--active')) return;

        const $siblings = getSiblings($subMenuWrap);
        const $subMenu = $subMenuWrap.querySelector('.sub-menu-dropdown');
        const $menuItemClose = $subMenuWrap.querySelector('.menu-item__close');

        if ($siblings) {
            $siblings.forEach($sibling => {
                if ($sibling.querySelector(`#${$subMenuWrap.id}`)) return;

                disableSubMenu($sibling);
            });
        }

        $subMenu.style.height = '0px';
            
        window.requestAnimationFrame(() => {
            $subMenuWrap.classList.add('menu-item--active');
            $subMenu.style.height = `${$subMenu.scrollHeight}px`;

            setTimeout(() => {
                $subMenu.style.height = '';
            }, 500);
        });

        $subMenuWrap.setAttribute('aria-expanded', 'true');
        $menuItemClose?.setAttribute('aria-expanded', 'true');
    };

    const disableSubMenu = ($subMenuWrap) => {
        const $menuItemClose = $subMenuWrap.querySelector('.menu-item__close');
        const $subMenu = $subMenuWrap.querySelector('.sub-menu-dropdown');

        if (!$subMenu) return;

        $subMenu.style.height = `${$subMenu.scrollHeight}px`;
    
        window.requestAnimationFrame(() => {
            $subMenuWrap.classList.remove('menu-item--active');
            $subMenu.style.height = '';
        });

        $subMenuWrap.setAttribute('aria-expanded', 'false');

        $menuItemClose?.setAttribute('aria-expanded', 'false');
    };

    const toggleSubMenu = ($subMenuWrap) => {
        if ($subMenuWrap.classList.contains('menu-item--active')) {
            disableSubMenu($subMenuWrap);
        } else {
            enableSubMenu($subMenuWrap);
        }
    };

    const initSubMenuDropdowns = () => {
        $subMenuDropdownWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('a');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();

                toggleSubMenu($subMenuWrap);
            });
        });
    };

    const initCloseButtons = () => {
        $subMenuDropdownWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('.menu-item__close');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                disableSubMenu($subMenuWrap, $subMenuToggle);
            });
        });
    };

    initSubMenuDropdowns();
    initCloseButtons();
})();
