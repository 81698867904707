(() => {
    const $siteMenu = document.querySelector('.site-menu-modal');

    if (!$siteMenu) return;
    
    const initSiteMenuNav = () => {
        const $parentSubMenuWraps = $siteMenu.querySelectorAll('.menu-item-has-children');

        const enableParentSubMenu = ($subMenuWrap, $subMenuToggle) => {
            const $subMenu = $subMenuWrap.querySelector('.sub-menu-dropdown');
            const $subMenuInner = $subMenuWrap.querySelector('.sub-menu-dropdown__inner');

            window.requestAnimationFrame(() => {
                $subMenu.style.height = `${$subMenuInner.offsetHeight}px`;

                $subMenuWrap.classList.add('menu-item--active');
        
                $subMenuToggle.setAttribute('aria-expanded', 'true');
                $subMenuToggle.setAttribute('aria-expanded', 'true');

                setTimeout(() => {
                    $subMenu.style.height = '';
                }, 250);
            });
        };
    
        const disableParentSubMenu = ($subMenuWrap, $subMenuToggle) => {
            const $subMenu = $subMenuWrap.querySelector('.sub-menu-dropdown');
            const $subMenuInner = $subMenuWrap.querySelector('.sub-menu-dropdown__inner');

            $subMenu.style.height = `${$subMenuInner.offsetHeight}px`;

            window.requestAnimationFrame(() => {
                $subMenu.style.height = '0';
                
                $subMenuWrap.classList.remove('menu-item--active');
        
                $subMenuToggle.setAttribute('aria-expanded', 'false');
                $subMenuToggle.setAttribute('aria-expanded', 'false');
            });
        };

        const initParentSubMenuWraps = () => {
            if (!$parentSubMenuWraps) return;
        
            $parentSubMenuWraps.forEach($parentSubMenuWrap => {
                const $subMenuToggle = $parentSubMenuWrap.querySelector('a, button');

                if ($subMenuToggle) {
                    $subMenuToggle.addEventListener('click', e => {
                        e.preventDefault();
                        
                        if ($parentSubMenuWrap.classList.contains('menu-item--active')) {
                            disableParentSubMenu($parentSubMenuWrap, $subMenuToggle);
                        } else {
                            enableParentSubMenu($parentSubMenuWrap, $subMenuToggle);
                        }
                    });
                }
            });
        };

        initParentSubMenuWraps();
    };

    initSiteMenuNav();
})();
