export const setBodyHeightVar = () => {
    document.documentElement.style.setProperty('--body-height', `${document.body.offsetHeight}px`);
};

(() => {
    const $siteHeader = document.querySelector('.site-header');
    const $siteMenuBar = document.querySelector('.site-menu-bar');
    
    const setBodyWidthVar = () => {
        document.documentElement.style.setProperty('--body-width', `${document.body.offsetWidth}px`);
    };
    
    const setBodyHeightVar = () => {
        document.documentElement.style.setProperty('--body-height', `${document.body.offsetHeight}px`);
    };

    const setSiteHeaderHeightVar = () => {
        document.documentElement.style.setProperty('--site-header-height', `${($siteHeader?.offsetHeight ?? 0)}px`);
    };

    const setSiteMenuBarHeightVar = () => {
        document.documentElement.style.setProperty('--site-menu-bar-height', `${($siteMenuBar?.offsetHeight ?? 0)}px`);
    };

    const setAdminBarHeightVar = () => {
        const $wpadminbar = document.querySelector('#wpadminbar');

        const topPos = $wpadminbar?.offsetHeight && window.innerHeight ? $wpadminbar?.offsetHeight : 0;

        document.documentElement.style.setProperty('--admin-bar-height', `${topPos}px`);
    };

    const setTopPositionVar = () => {
        const $wpadminbar = document.querySelector('#wpadminbar');

        const adminBarHeight = $wpadminbar?.offsetHeight && window.innerHeight ? $wpadminbar?.offsetHeight : 0;
        const siteHeaderHeight = $siteHeader?.offsetHeight ?? 0;
        const siteMenuBarHeight = $siteMenuBar?.offsetHeight ?? 0;

        document.documentElement.style.setProperty('--top-position', `${adminBarHeight + siteHeaderHeight + siteMenuBarHeight}px`);
    };

    const setContainerOffsetVar = () => {
        let containerOffset = 16;

        if (window.innerWidth >= 375) {
            containerOffset = 20;
        }
        
        if (window.innerWidth >= 768) {
            containerOffset = 32;
        }
        
        if (window.innerWidth >= 1024) {
            containerOffset = 64;
        }
        
        if (window.innerWidth >= 1200) {
            containerOffset = 100;
        }
        
        if (window.innerWidth >= 1440) {
            containerOffset = ((document.body.offsetWidth - 1440) / 2) + 100;
        }

        document.documentElement.style.setProperty('--container-offset', `${containerOffset}px`);
    };

    window.addEventListener('load', () => {
        setBodyWidthVar();
        setBodyHeightVar();
        setSiteHeaderHeightVar();
        setSiteMenuBarHeightVar();
        setAdminBarHeightVar();
        setTopPositionVar();
        setContainerOffsetVar();
    });

    window.addEventListener('resize', () => {
        setBodyWidthVar();
        setBodyHeightVar();
        setSiteHeaderHeightVar();
        setSiteMenuBarHeightVar();
        setAdminBarHeightVar();
        setTopPositionVar();
        setContainerOffsetVar();
    });

    setBodyWidthVar();
    setBodyHeightVar();
    setSiteHeaderHeightVar();
    setSiteMenuBarHeightVar();
    setAdminBarHeightVar();
    setTopPositionVar();
    setContainerOffsetVar();
})();
